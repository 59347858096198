import React from "react";
import gsap from "gsap";
import { useNavigate } from "react-router-dom";
import logo from "../images/logo-pure.png";
import burger from "../images/burger.png";
import thework from "../images/thework-menu.svg";
import como from "../images/como-menu.svg";
import partners from "../images/partners-menu.svg";
import send from "../images/send-menu.svg";

import theworkitalic from "../images/thework-menu-i.svg";
import comoitalic from "../images/como-menu-i.svg";
import partnersitalic from "../images/partners-menu-i.svg";
import senditalic from "../images/send-menu-i.svg";

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpened: "none"
    };
    this.isMobileOpened = false;
  }

  componentDidMount() {
    this.addListeners();
    this.onResize();
  }

  componentDidUpdate() {}

  addListeners = () => {
    window.addEventListener("resize", this.onResize);
  }

  onResize = (event) => {
    let w = window.innerWidth;
    let h = window.innerHeight;
    if (w > 880 && h > 430) {
      this.setState({
        mobileOpened: "block"
      });
    } else {
      if (this.isMobileOpened) {
        this.setState({
          mobileOpened: "block"
        });
      } else {
        this.setState({
          mobileOpened: "none"
        });
      }
    }
  };

  tapBurger = () => { 
    this.isMobileOpened = !this.isMobileOpened;
    this.onResize();
  }

  render() {
    let btnBurgerClose;
    if (!this.isMobileOpened) {
      btnBurgerClose = <img src={burger}></img>
    } else {
      btnBurgerClose = <span className="close-btn card-font-italic">x</span>
    }

    return (
      <>
        <div style={{ display: "none" }}>
          <img src={theworkitalic}></img>
          <img src={comoitalic}></img>
          <img src={senditalic}></img>
          <img src={partnersitalic}></img>
        </div>
        <div className="container-menu">
          <a href="#" className="logo-btn" onClick={(e) => {
            e.preventDefault();
            if (window.location.pathname == "/") {
              window.location.href = "/";
            } else {
              this.isMobileOpened = false;
              this.onResize();
              this.props["show-loader"](.5, 0, true, () => {
                this.props.navigation("/");
              });
              this.props["set-transition-value"](true);
            }
          }}><img src={logo} alt="Pure." /></a>
          <div className="inner-container-menu" style={{ display: this.state.mobileOpened }}>
            <ul>
              <li>
                <a href="#" className="como-menu" onClick={(e) => {
                  e.preventDefault();
                  if (window.location.pathname.includes("/how")) {
                    window.location.href = "/how";
                  } else {
                    this.isMobileOpened = false;
                    this.onResize();
                    this.props["show-loader"](.5, 0, false, () => {
                      this.props.navigation("/how");
                    });
                    this.props["set-transition-value"](true);
                  }
                }}><img src={como} alt="How"></img></a>
              </li>
              <li>
                <a href="#" className="work-menu" onClick={(e) => {
                  e.preventDefault();
                  if (window.location.pathname.includes("/thework")) {
                    window.location.href = "/thework";
                  } else {
                    this.isMobileOpened = false;
                    this.onResize();
                    this.props["show-loader"](.5, 0, false, () => {
                      this.props.navigation("/thework");
                    });
                    this.props["set-transition-value"](true);
                  }
                }}><img src={thework} alt="The work"></img></a>
              </li>
              <li>
                <a href="#" className="partners-menu" onClick={(e) => {
                  e.preventDefault();
                  if (window.location.pathname.includes("/partners")) {
                    window.location.href = "/partners";
                  } else {
                    this.isMobileOpened = false;
                    this.onResize();
                    this.props["show-loader"](.5, 0, false, () => {
                      this.props.navigation("/partners");
                    });
                    this.props["set-transition-value"](true);
                  }
                }}><img src={partners} alt="Partners"></img></a>
              </li>
              <li>
                <a href="#" className="send-menu" onClick={(e) => {
                  e.preventDefault();
                  if (window.location.pathname.includes("/contact")) {
                    window.location.href = "/contact";
                  } else {
                    this.isMobileOpened = false;
                    this.onResize();
                    this.props["show-loader"](.5, 0, false, () => {
                      this.props.navigation("/contact");
                    });
                    this.props["set-transition-value"](true);
                  }
                }}><img src={send} alt="Contact"></img></a>
              </li>
            </ul>
          </div>
          <a href="#" className="burger-btn" onClick={(e) => {
            e.preventDefault();
            this.tapBurger();
          }}>{btnBurgerClose}</a>
        </div>
      </>
    );
  }
}

export default function (props) {
  const navigation = useNavigate();
  return <Menu {...props} navigation={navigation} />;
}