import React from "react";
import gsap from "gsap";
import { useNavigate } from "react-router-dom";
import { isMobile } from "detect-touch-device";
import LocomotiveScroll from "locomotive-scroll";
import setBodyColor from "./SetBodyColor";
import setSocialDisplay from "./SetSocialDisplay";
import setSocialPosition from "./SetSocialPosition";

// import { Splide, SplideSlide } from '@splidejs/react-splide';
// import { Video } from '@splidejs/splide-extension-video';
// import '@splidejs/splide/dist/css/splide.min.css';
// import '@splidejs/splide/dist/css/themes/splide-skyblue.min.css';
// import '@splidejs/splide-extension-video/dist/css/splide-extension-video.min.css';

import next from "../images/next.png";
import prev from "../images/prev.png";

let video = "https://yeahpure.s3.us-east-2.amazonaws.com/Loop_SitePure.mp4";

let img1 = "https://yeahpure.s3.us-east-2.amazonaws.com/trevor-desktop.jpg";
let img2 = "https://yeahpure.s3.us-east-2.amazonaws.com/comex-dreams-desktop.jpg";
let img3 = "https://yeahpure.s3.us-east-2.amazonaws.com/chilango-desktop.jpg";
let img4 = "https://yeahpure.s3.us-east-2.amazonaws.com/duolingo-desktop.jpg";
let img5 = "https://yeahpure.s3.us-east-2.amazonaws.com/comex-regalon-desktop.jpg";
let img6 = "https://yeahpure.s3.us-east-2.amazonaws.com/aeromexico-desktop.jpg";
let img7 = "https://yeahpure.s3.us-east-2.amazonaws.com/comex-colormaps-desktop.jpg";
let img8 = "https://yeahpure.s3.us-east-2.amazonaws.com/spotify-desktop.jpg";

let img9 = "https://yeahpure.s3.us-east-2.amazonaws.com/trevor-mobile.jpg";
let img10 = "https://yeahpure.s3.us-east-2.amazonaws.com/comex-dreams-mobile.jpg";
let img11 = "https://yeahpure.s3.us-east-2.amazonaws.com/chilango-mobile.jpg";
let img12 = "https://yeahpure.s3.us-east-2.amazonaws.com/duolingo-mobile.jpg";
let img13 = "https://yeahpure.s3.us-east-2.amazonaws.com/comex-regalon-mobile.jpg";
let img14 = "https://yeahpure.s3.us-east-2.amazonaws.com/aeromexico-mobile.jpg";
let img15 = "https://yeahpure.s3.us-east-2.amazonaws.com/comex-colormaps-mobile.jpg";
let img16 = "https://yeahpure.s3.us-east-2.amazonaws.com/spotify-mobile.jpg";

class Work extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentImg: img1,
      currentTitle: "The Trevor Project.",
      currentBtnTitleMobile: "The Trevor Project",
      currentSubtitle: "Que la ignorancia deje de ser bandera.",
      currentText: "Por un #MéxicoAliade: convertir al país en un espacio<br class='br-txt-mobile'> seguro previniendo el suicidio en las juventudes LGBTQ+.",
      currentWidth: 0,
      rielWidth: "100vw",
      marginLeft: "0px",
      breakSubtitleClass: "no-visible",
    };
    // this.slider = React.createRef(); 
    this.rielBtns = React.createRef(); 
    this.prev = React.createRef();
    this.next = React.createRef();
    this.currentItem = 0;
    this.isActive = true;
    this.timer = 0;
    this.maxTime = 1000;
    this.itemText = React.createRef();
    this.items = [
      { 
        url: img1, 
        urlmobile: img9,
        btnTitle: "The Trevor Project",
        title: "The Trevor Project.", 
        subttitle: "Que la ignorancia deje de ser bandera.", 
        text: "Por un #MéxicoAliade: convertir al país en un espacio<br class='br-txt-mobile'> seguro previniendo el suicidio en las juventudes LGBTQ+.",
      },
      { 
        url: img2, 
        urlmobile: img10,
        btnTitle: "Comex",
        title: "Comex.", 
        subttitle: "Vive tus sueños.", 
        text: "Redefinir el propósito y posicionamiento de una marca icónica:<br class='br-txt-mobile'> de vender pintura a transformar la realidad de millones de mexicanos.",
      },
      { 
        url: img3, 
        urlmobile: img11, 
        btnTitle: "Radio Chilango",
        title: "Radio Chilango.", 
        subttitle: "La Radio que viene viene.", 
        text: "¿Lanzar una estación de radio en 2023?",
      },
      { 
        url: img4, 
        urlmobile: img12, 
        title: "Duolingo.", 
        btnTitle: "Duolingo",
        subttitle: "Owlspiracy theories.", 
        text: "Crear un culto y reclutar al fandom conspiranoico<br class='br-txt-mobile'> como nuevos miembros para adorar a Duo.",
      },
      { 
        url: img5, 
        urlmobile: img13, 
        title: "Comex.", 
        btnTitle: "Comex",
        subttitle: "Regalón Regalitro.", 
        text: "Transformar una promoción anclada en la cultura popular en un trigger emocional que construye marca a través del poder del color.",
      },
      { 
        url: img6, 
        urlmobile: img14, 
        btnTitle: "Aeroméxico",
        title: "Aeroméxico.", 
        subttitle: "Personas que son destinos.", 
        text: "Redefinir el concepto de geografía para cambiar la forma de vender boletos de avión: convirtiendo a las personas en millones de nuevos destinos.",
      },
      { 
        url: img7, 
        urlmobile: img15, 
        title: "Comex.", 
        btnTitle: "Comex",
        subttitle: "Color Maps.", 
        text: "Convertir calles con nombres de colores en el primer catálogo<br class='br-txt-mobile'> vivo de pinturas con Google Street View.",
      },
      { 
        url: img8, 
        urlmobile: img16, 
        title: "Spotify.", 
        btnTitle: "Spotify",
        subttitle: "Mexcla somos.", 
        text: "Celebrar la fuerza imparable de la creatividad mexicana como tendencia internacional a través de la frescura y talento de la Gen Z.",
      },
    ];
  }

  componentDidMount() {
    window.dataLayer.push({
      event: "pageview",
      pageName: "work",
      pageType: "pure",
      environtment: null,
      fullUrl: window.location.href,
      referrer: null,
      errorsMessage: null,
      country: null,
      adblockers: null,
      path: window.location.pathname,
      language: "es",
    });
    setBodyColor({color: "#000015"});
    setSocialDisplay({display: "none"});
    this.items.forEach((item) => {
      let newImage = new Image().src = item.url;
      let newImageMobile = new Image().src = item.urlmobile;
    });
    this.addListeners();
    this.onResize();
    setTimeout(() => {
      this.props["hide-loader"](0.5, 0);
    }, 1000);
    this.isActive = true;
    this.itemText.current.innerHTML = this.state.currentText;
    this.anim();
  }

  addListeners = () => {
    window.addEventListener("resize", this.onResize);
  }

  removeListeners = () => {
    window.removeEventListener("resize", this.onResize);
  }

  componentWillUnmount() {
    this.isActive = false;
    this.removeListeners();
  }

  componentDidUpdate() {}

  onResize = (event) => {
    let w = window.innerWidth;
    let h = window.innerHeight;

    // Calc width rieles
    let rielW = (w / 5) * 8;
    this.setState({  
      rielWidth: rielW
    });

    let imagen = (w > h) ? this.items[this.currentItem].url : this.items[this.currentItem].urlmobile;
    if (imagen == this.state.currentImg) {
      return;
    }
    this.setState({  
      currentImg: imagen
    });
  };

  anim = () => {
    this.timer++;
    if (this.timer >= this.maxTime) {
      this.timer = 0;
      if (this.currentItem < this.items.length - 1) {
        this.currentItem++;
      } else {
        this.currentItem = 0;
      }
      let item = this.items[this.currentItem];
      let w = window.innerWidth;
      let h = window.innerHeight;
      let imagen = (w > h) ? item.url : item.urlmobile;
      this.setState({
        currentImg: imagen,
        currentTitle: item.title,
        currentBtnTitleMobile: item.btnTitle,
        currentSubtitle: item.subttitle,
        currentText: item.text,
        breakSubtitleClass: (this.currentItem == 0 || this.currentItem == 2) ? "br-txt-mobile" : "no-visible"
      });
      this.itemText.current.innerHTML = item.text;
    }
    this.setMarginRiel();
    this.setState({
      currentWidth: (this.timer * 100) / this.maxTime,
      breakSubtitleClass: (this.currentItem == 0 || this.currentItem == 2) ? "br-txt-mobile" : "no-visible"
    });

    if (this.isActive) {
      window.requestAnimationFrame(this.anim);
    }
  }

  setMarginRiel = () => {
    console.log(this.currentItem);
    if (this.currentItem < 3) {
      this.setState({
        marginLeft: "0px"
      });
    } else {
      let w = window.innerWidth;
      let marginLeft = (w / 5) * 3;
      this.setState({
        marginLeft: "-" + marginLeft + "px"
      });
    }
  }

  onClickBtn = (num) => {
    this.currentItem = num;
    this.timer = 0;
    let item = this.items[this.currentItem];
    let w = window.innerWidth;
    let h = window.innerHeight;
    let imagen = (w > h) ? item.url : item.urlmobile;
    this.setState({
      currentImg: imagen,
      currentTitle: item.title,
      currentBtnTitleMobile: item.btnTitle,
      currentSubtitle: item.subttitle,
      currentText: item.text
    });
    this.itemText.current.innerHTML = item.text;
  }

  onClickPrevBtn = () => {
    if (this.currentItem == 0) {
      this.currentItem = this.items.length - 1;
    } else {
      this.currentItem--;
    }
    this.timer = 0;
    let item = this.items[this.currentItem];
    let w = window.innerWidth;
    let h = window.innerHeight;
    let imagen = (w > h) ? item.url : item.urlmobile;
    this.setState({
      currentImg: imagen,
      currentTitle: item.title,
      currentBtnTitleMobile: item.btnTitle,
      currentSubtitle: item.subttitle,
      currentText: item.text
    });
    this.itemText.current.innerHTML = item.text;
  }

  onClickNextBtn = () => {
    if (this.currentItem == this.items.length - 1) {
      this.currentItem = 0;
    } else {
      this.currentItem++;
    }
    this.timer = 0;
    let item = this.items[this.currentItem];
    let w = window.innerWidth;
    let h = window.innerHeight;
    let imagen = (w > h) ? item.url : item.urlmobile;
    this.setState({
      currentImg: imagen,
      currentTitle: item.title,
      currentBtnTitleMobile: item.btnTitle,
      currentSubtitle: item.subttitle,
      currentText: item.text
    });
    this.itemText.current.innerHTML = item.text;
  }

  render() {
    const { navigation } = this.props;
    return (
      <>
        <div className="container-section-work">
          <h2 className="section-title">Creatividad <span className="card-font-italic">Singular.</span></h2>
          <div className="container-work-imgs">
            <div className="img-one" style={{ backgroundImage: `url(${this.state.currentImg})` }}></div>
          </div>
          <div className="footer-work">
            <div className="footer-work-titles">
              <h3>{this.state.currentTitle} <br className={this.state.breakSubtitleClass}></br><span className="card-font-italic">{this.state.currentSubtitle}</span></h3>
              <p ref={this.itemText}></p>
            </div>
            <div className="footer-work-items">
              <div className="work-riel-btns" ref={this.rielBtns} style={{ width: this.state.rielWidth, marginLeft: this.state.marginLeft }}>
                <div className="footer-work-block">
                  <div className="footer-work-line">
                    <div style={{ width: `${(this.currentItem == 0) ? this.state.currentWidth : 0}%` }}></div>
                  </div>
                  <a className="footer-work-item" href="#" onClick={(event) => { event.preventDefault(); this.onClickBtn(0); }}>
                    <span className="footer-work-label">The Trevor Project</span>
                  </a>
                </div>
                
                <div className="footer-work-block">
                  <div className="footer-work-line">
                    <div style={{ width: `${(this.currentItem == 1) ? this.state.currentWidth : 0}%` }}></div>
                  </div>
                  <a className="footer-work-item" href="#" onClick={(event) => { event.preventDefault(); this.onClickBtn(1); }}>
                    <span className="footer-work-label">Comex</span>
                  </a>
                </div>

                <div className="footer-work-block">
                  <div className="footer-work-line">
                    <div style={{ width: `${(this.currentItem == 2) ? this.state.currentWidth : 0}%` }}></div>
                  </div>
                  <a className="footer-work-item" href="#" onClick={(event) => { event.preventDefault(); this.onClickBtn(2); }}>
                    <span className="footer-work-label">Radio Chilango</span>
                  </a>
                </div>

                <div className="footer-work-block">
                  <div className="footer-work-line">
                    <div style={{ width: `${(this.currentItem == 3) ? this.state.currentWidth : 0}%` }}></div>
                  </div>
                  <a className="footer-work-item" href="#" onClick={(event) => { event.preventDefault(); this.onClickBtn(3); }}>
                    <span className="footer-work-label">Duolingo</span>
                  </a>
                </div>

                <div className="footer-work-block">
                  <div className="footer-work-line">
                    <div style={{ width: `${(this.currentItem == 4) ? this.state.currentWidth : 0}%` }}></div>
                  </div>
                  <a className="footer-work-item" href="#" onClick={(event) => { event.preventDefault(); this.onClickBtn(4); }}>
                    <span className="footer-work-label">Comex</span>
                  </a>
                </div>

                <div className="footer-work-block">
                  <div className="footer-work-line">
                    <div style={{ width: `${(this.currentItem == 5) ? this.state.currentWidth : 0}%` }}></div>
                  </div>
                  <a className="footer-work-item" href="#" onClick={(event) => { event.preventDefault(); this.onClickBtn(5); }}>
                    <span className="footer-work-label">Aeroméxico</span>
                  </a>
                </div>
                
                <div className="footer-work-block">
                  <div className="footer-work-line">
                    <div style={{ width: `${(this.currentItem == 6) ? this.state.currentWidth : 0}%` }}></div>
                  </div>
                  <a className="footer-work-item" href="#" onClick={(event) => { event.preventDefault(); this.onClickBtn(6); }}>
                    <span className="footer-work-label">Comex</span>
                  </a>
                </div>

                <div className="footer-work-block">
                  <div className="footer-work-line">
                    <div style={{ width: `${(this.currentItem == 7) ? this.state.currentWidth : 0}%` }}></div>
                  </div>
                  <a className="footer-work-item" href="#" onClick={(event) => { event.preventDefault(); this.onClickBtn(7); }}>
                    <span className="footer-work-label">Spotify</span>
                  </a>
                </div>
              </div>
            </div>
            <div className="footer-work-single-line">
              <div style={{ width: `${this.state.currentWidth}%` }}></div>
            </div>
            <a className="footer-work-single-item" href="#" onClick={(event) => { event.preventDefault(); }}>
              <span className="footer-work-label">{this.state.currentBtnTitleMobile}</span>
            </a>
          </div>
          <a className="prev-btn" href="#" onClick={(event) => { event.preventDefault(); this.onClickPrevBtn(); }}>
            <img src={prev}></img>
          </a>
          <a className="next-btn" href="#" onClick={(event) => { event.preventDefault(); this.onClickNextBtn(); }}>
            <img src={next}></img>
          </a>
        </div>
      </>
    );
  }
}

export default function (props) {
  const navigation = useNavigate();
  return <Work {...props} navigation={navigation} />;
}
