import React from "react";
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import ReactDOM from "react-dom";
import TagManager from "react-gtm-module";
import "bootstrap/dist/css/bootstrap.min.css";
import Fonts from "./css/fonts.css";
import Styles from "./css/styles.css";
import { useSpring, animated } from "react-spring";
import { Controller } from "react-spring/renderprops";
import { Quart, Quint } from "gsap";
import Utils from "./helpers/Utils";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isTablet,
} from "react-device-detect";
import DOMPurify from "dompurify";
import gsap from "gsap";
import setZIndexMenu from "./components/SetZIndexMenu";
import { TWEEN } from "three/examples/jsm/libs/tween.module.min.js";
import Cookies from "js-cookie";
import Home from "./components/Home";
import Como from "./components/Como";
import Work from "./components/Work";
import Partners from "./components/Partners";
import Contact from "./components/Contact";
import Menu from "./components/Menu";
import Splash from "./components/Splash";
import { render } from "react-dom";
import { Stage, Container, Sprite, AnimatedSprite } from "@pixi/react";
import { Assets, Texture } from 'pixi.js';
import Lottie from "lottie-react";
import groovyWalkAnimation from "./images/groovyWalk.json";
import instagram from "./images/ig.svg";
import linkedin from "./images/in.svg";
import pin from "./images/pin.svg";

// const tagManagerArgs = {
//   gtmId: "G-7YB7XQ12E8",
// };

//TagManager.initialize(tagManagerArgs);

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      containerLoaderDisplay: "block",
      animatedSprite0: [],
      animatedSprite1: [],
      clip0Visible: true,
      clip1Visible: false,
      canvasW: window.innerWidth,
      canvasH: window.innerHeight,
      animPositionX: window.innerWidth / 2,
      animPositionY: window.innerHeight / 2,
      showCookies: "none"
    };
    this.containerLoader = React.createRef();
    this.getTexture = this.getTexture.bind(this);
    this.animation0 = React.createRef();
    this.animation1 = React.createRef();
    this.home = React.createRef();
    this.text0 = React.createRef();
    this.text1 = React.createRef();
    this.cursor = React.createRef();
    this.isTransition = true;
  }

  componentDidMount() {
    let newImage = new Image().src = "https://yeahpure.s3.us-east-2.amazonaws.com/trevor-desktop.jpg";
    let newImage1 = new Image().src = "https://yeahpure.s3.us-east-2.amazonaws.com/trevor-mobile.jpg";

    if (Cookies.get("cookies-acceptance") == undefined) {
      this.setState({
        showCookies: "flex"
      });
    } else {
      let cookies = Cookies.get("cookies-acceptance");
      if (cookies === "true") {
        this.setState({
          showCookies: "none"
        });
      } else {
        this.setState({
          showCookies: "flex"
        });
      }
    }
    this.addListeners();
    this.getFirstTexture();
  }

  getTexture = async (url) => {
    let response = await Assets.load(url);
    Assets.reset();
    return response;
  };

  addListeners = () => {
    window.addEventListener("mousemove", this.onMouseMove);
    window.addEventListener("resize", this.onResize);
  }

  onMouseMove = (event) => {
    this.cursor.current.style.left = event.clientX + "px";
    this.cursor.current.style.top = event.clientY + "px";
  };

  onResize = (event) => {
    this.setState({
      canvasW: window.innerWidth,
      canvasH: window.innerHeight,
      animPositionX: window.innerWidth / 2,
      animPositionY: window.innerHeight / 2
    });
  };

  getFirstTexture = () => {
    this.getTexture("/images/texture-0.json")
    .then((response) => {
      var frames = [];
      for (let k in response.textures) {
        frames.push(response.textures[k]);
      }
      this.setState({
        animatedSprite0: frames
      });
      this.getSecondTexture();
    })
    .catch((err) => {
      console.log(err);
    });
  }

  getSecondTexture = () => {
    this.getTexture("/images/texture-1.json")
      .then((response) => {
        var frames = [];
        for (let k in response.textures) {
          frames.push(response.textures[k]);
        }
        this.setState({
          animatedSprite1: frames
        });
        setTimeout(() => {
          if (window.location.pathname == "/") {
            this.animation0.current.gotoAndPlay(0);
          }
        }, 100);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  animComplete = () => {
    if (this.state.clip0Visible == true) {
      this.setState({
        clip0Visible: false,
        clip1Visible: true
      });
      setTimeout(() => {
        this.animation1.current.gotoAndPlay(0);
      }, 100);
    }
  }

  animComplete1 = () => {
    gsap.to(this.animation1.current, {
      duration: 0.7,
      alpha: 0,
      ease: "linear",
      delay: 0,
      onComplete: () => {
        this.finishSplash();
        this.setState({
          clip0Visible: false,
          clip1Visible: false
        });
        this.animation0.current.gotoAndStop(0);
        this.animation1.current.gotoAndStop(0);
        this.animation1.current.alpha = 1;
      },
      onUpdate: () => {},
    });
  }

  showLoader = (
    duration,
    delay,
    showSplash = false,
    completion
  ) => {
    if (this.isTransition == true) {
      return;
    }
    this.setState({
      containerLoaderDisplay: "block"
    });
    gsap.to(this.containerLoader.current, {
      duration: duration,
      opacity: 1,
      ease: "linear",
      delay: delay,
      onComplete: () => {
        if (completion) {
          completion();
          if (showSplash == true) {
            this.setState({
              clip0Visible: true,
              clip1Visible: false,
            });
            this.animation0.current.gotoAndPlay(0);
          }
        }
      },
      onUpdate: function () {},
    });
  };

  hideLoader = (duration, delay) => {
    gsap.to(this.containerLoader.current, {
      duration: duration,
      opacity: 0,
      ease: "linear",
      delay: delay,
      onComplete: () => {
        this.setState({
          containerLoaderDisplay: "none",
        });
        setZIndexMenu({zindex: 9999999999});
        this.isTransition = false;
      },
    });
  };

  tapAcceptCookies = () => {
    Cookies.set("cookies-acceptance", true);
    this.setState({
      showCookies: "none"
    });
  }

  tapRejectCookies = () => {
    Cookies.set("cookies-acceptance", false);
    this.setState({
      showCookies: "none"
    });
  }

  setTransitionValue = (value) => {
    this.isTransition = value;
  }

  render() {
    let splashCanvas;
    let w = window.innerWidth;
    let h = window.innerHeight;
    if (this.state.animatedSprite0.length === 0 || this.state.animatedSprite1.length === 0) {
      splashCanvas = <span></span>;
    } else {
      w = (w > 720) ? 720 : w;
      h = (w > 720) ? 354 : ((w) * 354) / 720;
      splashCanvas =  <Stage className="splash-canvas" width={this.state.canvasW} height={this.state.canvasH} options={{ backgroundAlpha: 0 }}>
                        <AnimatedSprite 
                          x={this.state.animPositionX}
                          y={this.state.animPositionY}
                          width={w}
                          height={h}
                          ref={this.animation0}
                          anchor={0.5}
                          textures={this.state.animatedSprite0}
                          isPlaying={false}
                          initialFrame={0}
                          animationSpeed={0.4}
                          loop={false}
                          onComplete={this.animComplete}
                          visible={this.state.clip0Visible} />
                        <AnimatedSprite 
                          x={this.state.animPositionX}
                          y={this.state.animPositionY}
                          width={w}
                          height={h}
                          ref={this.animation1}
                          anchor={0.5}
                          textures={this.state.animatedSprite1}
                          isPlaying={false}
                          initialFrame={0}
                          animationSpeed={0.4}
                          loop={false}
                          onComplete={this.animComplete1}
                          visible={this.state.clip1Visible} />
                      </Stage>;
    }

    return (
      <Router>
        <div className="App">
          <Menu
            show-loader={this.showLoader}
            hide-loader={this.hideLoader}
            set-transition-value={this.setTransitionValue}
          ></Menu>
          <Routes>
            <Route
              exact
              path="/"
              element={
                <Home
                  show-loader={this.showLoader}
                  hide-loader={this.hideLoader}
                  finishSplash={finishSplash => this.finishSplash = finishSplash}
                ></Home>
              }
            ></Route>
            <Route
              exact
              path="/how"
              element={
                <Como
                  show-loader={this.showLoader}
                  hide-loader={this.hideLoader}
                ></Como>
              }
            ></Route>
            <Route
              exact
              path="/thework"
              element={
                <Work
                  show-loader={this.showLoader}
                  hide-loader={this.hideLoader}
                ></Work>
              }
            ></Route>
            <Route
              exact
              path="/partners"
              element={
                <Partners
                  show-loader={this.showLoader}
                  hide-loader={this.hideLoader}
                ></Partners>
              }
            ></Route>
            <Route
              exact
              path="/contact"
              element={
                <Contact
                  show-loader={this.showLoader}
                  hide-loader={this.hideLoader}
                ></Contact>
              }
            ></Route>
          </Routes>
          <div className="social">
            <p className="hashtag">#Buscamos<span className="card-font-italic">Problemas</span></p>
            <a href="https://www.linkedin.com/company/pure-creative-chemistry" className="linkedin" target="_blank">
              <img src={linkedin}></img>
            </a>
            <a href="https://www.instagram.com/yeahpure/" target="_blank">
              <img src={instagram}></img>
            </a>
            <a href="https://maps.app.goo.gl/zeGKZzge9wvWBeao6" target="_blank">
              <img src={pin}></img>
            </a>
          </div>
          <div
            ref={this.containerLoader}
            style={{ display: this.state.containerLoaderDisplay }}
            className="container-splash"
          >
            <div className="container-splash-canvas">
              {splashCanvas}
            </div>
            <div className="container-splash-texts">
              <span className="splash-text" ref={this.text0}>Buscamos</span>
              <span className="splash-text" ref={this.text1}>problemas</span>
            </div>
          </div>
          <div className="container-cookies" style={{ display: this.state.showCookies }}>
            <div>
              <h4>Toma una cookie.</h4>
            </div>
            <div className="cookies-btns">
              <a href="#" onClick={(event) => { event.preventDefault(); this.tapRejectCookies(); }} className="cookies-btn">No, gracias</a>
              <a href="#" onClick={(event) => { event.preventDefault(); this.tapAcceptCookies(); }} className="cookies-btn">¡Ok!</a>
            </div>
          </div>
          <div className="cursor" ref={this.cursor}></div>
        </div>
      </Router>
    );
  }
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);
