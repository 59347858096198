import React from "react";
import gsap from "gsap";
import { useNavigate } from "react-router-dom";
import { isMobile } from "detect-touch-device";
import LocomotiveScroll from "locomotive-scroll";
import setBodyColor from "./SetBodyColor";
import setSocialDisplay from "./SetSocialDisplay";
import setSocialPosition from "./SetSocialPosition";

import diagrama from "../images/diagrama.png";

class Como extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    window.dataLayer.push({
      event: "pageview",
      pageName: "como",
      pageType: "pure",
      environtment: null,
      fullUrl: window.location.href,
      referrer: null,
      errorsMessage: null,
      country: null,
      adblockers: null,
      path: window.location.pathname,
      language: "es",
    });
    this.addListeners();
    this.setBodyBg();
    setSocialDisplay({display: "flex"});
    setSocialPosition({position: "relative"});
    setTimeout(() => {
      this.props["hide-loader"](0.5, 0);
    }, 1000);
  }

  componentWillUnmount() {
    this.removeListeners();
  }

  componentDidUpdate() {}

  addListeners = () => {
    window.addEventListener("resize", this.onResize);
  }

  removeListeners = () => {
    window.removeEventListener("resize", this.onResize);
  }

  onResize = (event) => {
    this.setBodyBg();
  };

  setBodyBg = () => {
    let w = window.innerWidth;
    let h = window.innerHeight;
    if (w > h) {
      setBodyColor({color: "#000015 url(https://yeahpure.s3.us-east-2.amazonaws.com/como-desktop.jpg) no-repeat"});
    } else {
      setBodyColor({color: "#000015 url(https://yeahpure.s3.us-east-2.amazonaws.com/como-mobile.jpg) no-repeat"});
    }
  }

  render() {
    const { navigation } = this.props;
    return (
      <>
        <div className="container-section como">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-sm-12 diagrama">
                <h1>Nuestra <span className="card-font-italic">esencia</span></h1>
                <p className="show-mobile"> 
                  Pure. es una compañía creativa <br className="br-txt-mobile"></br>que incuba y acelera el crecimiento con ideas singulares. 
                </p>
                <p className="show-mobile">
                  Nuestro enfoque está 100% en resolver problemas de negocio con creatividad.
                </p>
                <img src={diagrama} className="diagrama-img-1 show-mobile" />
                <p className="show-mobile">
                  El punto de partida es una pregunta:
                </p>
                <p className="show-mobile card-font-italic">
                  ¿Cuál es el problema que está afectando <br className="br-txt-mobile"></br>al negocio?
                </p>
                 
                <p className="show-desktop">
                  Pure. es una compañía creativa que incuba y acelera<br></br> el crecimiento y transformación de las empresas, <br></br>instituciones y comunidades con ideas singulares. 
                </p>
                <p className="show-desktop">
                  Nuestro enfoque está 100% en resolver problemas complejos de negocio con creatividad, con un pensamiento agnóstico <br></br>en cuanto a disciplinas, formatos, medios, plataformas <br></br>y tecnologías. 
                </p>
                <p className="show-desktop">
                  El punto de partida es una pregunta: 
                </p>
                <p className="show-desktop card-font-italic">
                  ¿Cuál es el problema que está afectando al negocio?
                </p>
              </div>
              <div className="col-md-6 col-sm-12 diagrama-img show-desktop">
                <img src={diagrama} />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default function (props) {
  const navigation = useNavigate();
  return <Como {...props} navigation={navigation} />;
}
