import React from "react";
import gsap from "gsap";
import { useNavigate } from "react-router-dom";
import { isMobile } from "detect-touch-device";
import LocomotiveScroll from "locomotive-scroll";
import { render } from "react-dom";
import { Stage, Container, Sprite } from "@pixi/react";
import { Assets } from 'pixi.js';
import setBodyColor from "./SetBodyColor";
import setSocialDisplay from "./SetSocialDisplay";
import setSocialPosition from "./SetSocialPosition";

import logo from "../images/pure-logo.png";
let homeVideoDesktop = "https://yeahpure.s3.us-east-2.amazonaws.com/video-loop-desktop.mp4";
let homeVideoMobile = "https://yeahpure.s3.us-east-2.amazonaws.com/Loop_SitePureMobile.mp4";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentVideoURL: homeVideoDesktop
    };
    this.modal_video = React.createRef();
    this.finishSplash = this.finishSplash.bind(this);
  }

  componentDidMount() {
    window.dataLayer.push({
      event: "pageview",
      pageName: "home",
      pageType: "pure",
      environtment: null,
      fullUrl: window.location.href,
      referrer: null,
      errorsMessage: null,
      country: null,
      adblockers: null,
      path: window.location.pathname,
      language: "es",
    });
    setBodyColor({color: "#000015"});
    setSocialDisplay({display: "flex"});
    setSocialPosition({position: "absolute"});
    this.props.finishSplash(this.finishSplash);
    this.addListeners();
    this.setVideoOrigin();
    this.modal_video.current.load();
    this.modal_video.current.pause();
  }

  componentWillUnmount() {
    this.removeListeners();
    this.modal_video.current.pause();
  }

  componentDidUpdate() {}

  addListeners = () => {
    window.addEventListener("focus", this.onFocus);
    window.addEventListener("blur", this.onBlur);
    window.addEventListener("resize", this.onResize);
  }

  removeListeners = () => {
    window.removeEventListener("focus", this.onFocus);
    window.removeEventListener("blur", this.onBlur);
    window.removeEventListener("resize", this.onResize);
  }

  finishSplash = () => {
    this.props["hide-loader"](0.5, 1);
    this.modal_video.current.currentTime = 0;
    this.modal_video.current.play();
  }

  onFocus = () => {
    console.log("Tab is in focus");
    this.modal_video.current.play();
  };

  onBlur = () => {
    console.log("Tab is blurred");
    this.modal_video.current.pause();
  };

  onResize = (event) => {
    this.setVideoOrigin();
  };

  setVideoOrigin = () => {
    let w = window.innerWidth;
    let h = window.innerHeight;
    let video = (w > h) ? homeVideoDesktop : homeVideoMobile;
    if (video == this.state.currentVideoURL) {
      return;
    }
    this.setState({  
      currentVideoURL: video
    });
    this.modal_video.current.pause();
    this.modal_video.current.load();
  };

  render() {
    const { navigation } = this.props;
    return (
      <>
        <div className="container-section-home">
          <video
            ref={this.modal_video}
            playsInline
            webkit-playsinline="true"
            width="100%"
            height="100%"
            loop
            autoPlay
            muted="muted"
            style={{ objectFit: "cover" }}
          >
            <source src={this.state.currentVideoURL} type="video/mp4"></source>
          </video>
        </div>
      </>
    );
  }
}

export default function (props) {
  const navigation = useNavigate();
  return <Home {...props} navigation={navigation} />;
}
